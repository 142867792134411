/**
 * Utility styles
 *
 */

.u-container {
  max-width: 100%;
  margin-left: 22rem;
  margin-right: 2rem;
}

.u-row {
  display: flex;
  align-items: center;
}

.u-block {
  display: block;
}

.u-gap-vert {
  margin-top: $project-gutter;
  margin-bottom: $project-gutter;
}

.u-gap-hor {
  margin-right: $project-gutter;
  margin-left: $project-gutter;
}

.u-gap-hor--sm {
  margin-right: $project-gutter-sm;
  margin-left: $project-gutter-sm;
}

.u-gap-right {
  margin-right: $project-gutter;
}

.u-gap-right-sm {
  margin-right: $project-gutter-sm;
}

.u-gap-bottom {
  margin-bottom: $project-gutter;
}

.u-gap-bottom-sm {
  margin-bottom: $project-gutter-sm;
}

.u-gap-bottom-xs {
  margin-bottom: $project-gutter-xs;
}

.u-gap-left {
  margin-left: $project-gutter;
}

.u-gap-left--sm {
  margin-left: $project-gutter-sm;
}

.u-gap-top {
  margin-top: $project-gutter;
}
.u-gap-top--sm {
  margin-top: $project-gutter-sm;
}

.u-gap-bottom {
  margin-bottom: $project-gutter;
}
.u-gap-bottom--sm {
  margin-bottom: $project-gutter-sm;
}

.u-gap-bottom-none {
  &.u-gap-bottom-none {
    margin-bottom: 0;
  }
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-gap-vert {
  margin-top: $project-gutter;
  margin-bottom: $project-gutter;
}

.is-sticky {
  position: sticky;
}

.u-text-error {
  color: $project-color-brand-red;
}

.u-text-success {
  color: $project-color-brand-green;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-position-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.u-ellipsis-3rd {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
