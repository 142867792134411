.ReactModal__Overlay {
  z-index: 10;
  background-color: $project-color-black30;
}

.c-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 90vw;
  max-height: 50vh;
  background-color: $project-color-white;
  box-shadow: 0 0 0.5rem 0 $project-color-black10;
  border-radius: $project-radius-lg;
}

.c-modal--prompt {
  width: 20rem;
  height: auto;
}

.c-modal__header {
  padding: $project-gutter;
  border-bottom: 1px solid $project-color-gray-light;
}

.c-modal__content {
  padding: $project-gutter;
  background-color: $project-color-white;

  .c-modal--prompt & {
    text-align: center;
    border-radius: 0 0 $project-radius-lg $project-radius-lg;
  }
}
