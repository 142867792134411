/**
 * Layouts
 *
 */

.l-app-body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.l-app-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 4rem;
  border-bottom: 1px solid $project-color-gray;
  background-color: $project-color-white;

  .c-app-logo {
    width: 3rem;
    margin: $project-gutter-sm;
  }
}

.l-app-content {
  flex-grow: 1;
  margin-top: 5rem;
  margin-bottom: $project-gutter-lg;
}

.has-centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
