/*
 * Input styles
 *
 **/

.c-question-list {
  margin-top: $project-gutter-lg;
  margin-bottom: 3rem;

  > .c-question-item {
    padding: $project-gutter-sm;
    border-bottom: 1px solid $project-color-gray-light;

    &.c-question-item {
      margin-bottom: 0;
    }

    &:nth-child(odd) {
      background-color: $project-color-white-shade;
    }
  }
}

.c-question-item {
  display: grid;
  grid-gap: $project-gutter;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: $project-gutter-sm;
}

.c-question-item__label {
  grid-column: 1/2;
}

.c-question-item__answer {
  grid-column: 3/-1;

  label {
    width: 100%;
  }
}

.c-form-group {
  color: $project-color-brand-red;

  span {
    margin-left: 0.3rem;
  }

  .c-form-switch__toggle {
    margin-left: 0 !important;
  }

  .c-input-group__addon {
    margin-left: 0 !important;
  }

  label {
    display: inline-flex;
    color: $project-color-black;
  }

  &:not(:last-of-type) {
    margin-bottom: $project-gutter;
  }

  .u-text-error {
    margin-top: $project-gutter-xs;
    font-size: $project-font-size-sm;
  }
}

.c-input-group {
  display: flex;
  filter: drop-shadow(0 0 0.2rem $project-color-black10);

  .c-form-control {
    filter: none;
  }
}

.c-form-check {
  margin-right: $project-gutter-sm;

  + .c-control-label {
    display: inline-block;
    margin-bottom: 0;
  }
}

.c-input-group__addon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $project-gutter-sm;
  background-color: $project-color-white;
  border: 1px solid $project-color-gray;
  border-left: none;
  border-radius: 0 0.5rem 0.5rem 0;
}

.c-control-label {
  display: block;
  margin-bottom: $project-gutter-sm;
}

.c-form-control {
  display: block;
  width: 100%;
  padding: $project-gutter-sm;
  font-size: $project-font-size-stack;
  background-color: $project-color-white;
  border: 1px solid $project-color-gray;
  border-radius: 0.5rem;
  resize: none;

  .c-input-group & {
    border-right: none;
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &:focus,
  &:active {
    outline: none;
    filter: drop-shadow(0 0 0.5rem $project-color-brand-purple50);
    border-color: $project-color-brand-purple;
  }

  &[disabled],
  &.is-disabled {
    opacity: 0.75;
    background-color: $project-color-gray-light;
    cursor: not-allowed;
  }
}

select {
  &.c-form-control {
    height: 2.25rem;
  }
}

.c-form-switch {
  display: inline-flex;
  align-items: center;
  margin: 0;
  position: relative;

  .c-form-switch__input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:focus + .c-form-switch__toggle {
      outline: $project-color-brand-purple50 solid 1px;
      box-shadow: 0 0 8px $project-color-brand-purple50;
    }

    &:disabled + .c-form-switch__toggle {
      cursor: not-allowed;
      filter: grayscale(100%);
      opacity: 0.6;
    }

    &:disabled + .c-form-switch__toggle::after {
      box-shadow: none;
    }

    &:checked + .c-form-switch__toggle::before {
      background: $project-color-brand-blue;
    }

    &:checked + .c-form-switch__toggle::after {
      transform: translate(90%, -50%);
    }
  }

  .c-form-switch__toggle {
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      display: block;
      margin: 0 $project-gutter-xs;
      transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
      background: $project-color-gray;
    }

    &::before {
      width: 2.5rem;
      height: 1.5rem;
      border-radius: $project-radius-xl;
      opacity: 0.6;
    }

    &::after {
      position: absolute;
      top: 50%;
      transform: translate(10%, -50%);
      height: 1.2rem;
      width: 1.2rem;
      background-color: $project-color-white-dirty;
      border-radius: $project-radius-round;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }
}
