/*
 * Button styles
 *
 **/

.c-btn {
  display: inline-block;
  font-size: $project-font-size-stack;
  line-height: 1.5;
  padding: $project-gutter-sm $project-gutter;
  color: $project-color-blackish;
  background-color: $project-color-gray-light;
  border: none;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    background-color: $project-color-gray;
    cursor: pointer;
    outline: none;
  }

  &[disabled],
  &.is-disabled {
    background-color: $project-color-gray;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      cursor: not-allowed;
      background-color: $project-color-gray;
    }
  }
}

.c-btn--sm {
  padding: $project-gutter-sm;
  font-size: $project-font-size-sm;
}

.c-btn--wide {
  padding-right: $project-gutter-lg;
  padding-left: $project-gutter-lg;
}

.c-btn--primary {
  background-color: $project-color-brand-purple;
  color: $project-color-white;

  &:hover,
  &:focus,
  &:active {
    background-color: $project-color-brand-purple-dark;
  }
}

.c-btn--delete {
  background-color: $project-color-delete-red;
  color: $project-color-white;

  &:hover {
    background-color: $project-color-delete-red-dark;
  }
}

.c-btn--fb {
  color: $project-color-white;
  background-color: $project-color-facebook;

  &:hover,
  &:focus,
  &:active {
    background-color: $project-color-facebook-dark;
  }
}

.c-btn--g {
  color: $project-color-white;
  background-color: $project-color-google;

  &:hover,
  &:focus,
  &:active {
    background-color: $project-color-google-dark;
  }
}

.u-btn--block-right {
  display: block;
  margin-left: auto !important;
}
