.c-app-nav {
  display: flex;
  align-items: stretch;
  font-weight: bold;

  > a,
  > button {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: $project-font-size-stack;
    padding: $project-gutter;
    color: $project-color-blackish;
    border: none;
    border-left: 1px solid $project-color-gray;
    text-decoration: none;

    > img {
      max-width: 2.5rem;
      max-height: 2.5rem;
    }
  }
}

.c-main-nav {
  position: fixed;
  top: 4rem;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $project-gutter;
  color: $project-color-white;
  background-color: $project-color-brand-purple;

  > div {
    margin: 0.5rem auto;
  }
}

.c-main-nav--open {
  position: fixed;
  top: 4rem;
  bottom: 0;
  left: 0;
  z-index: 200;
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $project-gutter;
  color: $project-color-white;
  background-color: $project-color-brand-purple;
  overflow-y: auto;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0.5rem 0;

    span {
      margin-left: 1rem;
    }

    a {
      min-width: 3rem;
      min-height: 3rem;
    }
  }
}

.c-nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $project-color-white;
  text-decoration: none;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 2px dotted $project-color-white;
  background-color: transparent;
}

.c-nav-link {
  font-size: 0.85rem;
  color: $project-color-white;
  text-decoration: none;
  cursor: pointer;
}

.c-media-grid {
  display: flex;

  @supports (display: grid) {
    display: grid;
    grid-gap: $project-gutter-lg;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.c-media {
  display: flex;
}

.c-media__object {
  margin-right: $project-gutter;
}

.c-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $project-gutter-lg;
  padding-bottom: $project-gutter;
  border-bottom: 1px solid $project-color-blackish;

  &.is-sticky,
  &.is-fixed {
    top: 4rem;
    z-index: 10;
    padding-top: $project-gutter;
    background-color: $project-color-white-dirty;
  }

  &.is-fixed {
    position: fixed;
    width: calc(100vw - 24rem);
  }
}

.c-section-title {
  font-weight: bold;
  font-size: $project-font-size-lg;

  &.c-section-title {
    margin-bottom: 0;
  }
}

.c-section-actions {
  display: flex;
  align-items: center;
}

.c-section-subtitle {
  font-size: $project-font-size-lg;
  font-weight: 700;
  margin-bottom: $project-gutter-lg;
}

.c-login-form {
  width: 30rem;

  h1 {
    font-size: $project-font-size-lg;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  .c-app-logo {
    display: block;
    width: 7rem;
    margin-right: auto;
    margin-bottom: $project-gutter-lg;
    margin-left: auto;
  }
}

.c-social-btns {
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $project-gutter;
  }
}

.c-form {
  padding-top: 6rem;
}

.c-table {
  width: 100%;
  border-collapse: collapse;

  &.u-width-80 {
    width: 80%;
    margin: 0 auto;
  }

  > thead {
    > tr {
      > th,
      > td {
        font-weight: bold;
        background-color: $project-color-gray-light;
      }
    }
  }

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > td,
      > th {
        padding: $project-gutter-sm;
        border: 1px solid $project-color-gray;

        a {
          display: block;
        }
      }
    }
  }

  > tbody {
    > tr {
      &:nth-child(even) {
        > td {
          background-color: $project-color-white-shade;
        }
      }
      &:nth-child(odd) {
        > td {
          background-color: $project-color-white;
        }
      }
    }
  }

  > tbody {
    > tr {
      &.u-red-background {
        > td {
          color: $project-color-white;
          background-color: $project-color-google;
          > a {
            color: $project-color-white;
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .is-short {
    width: 5rem;
  }

  .c-form-group {
    display: flex;
    justify-content: center;
  }
}

.c-table-scroll {
  max-height: calc(100vh - 12rem);
  overflow-y: auto;
  margin-top: 1rem;
}

.c-grid {
  display: flex;

  @supports (display: grid) {
    display: grid;
    grid-gap: 1rem;
  }
}

.c-grid--halves {
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}

.c-grid--thirds {
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.c-grid--fourths {
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.c-grid--content-aside {
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 2fr 1fr;
  }
}

.c-grid--aside-content {
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 2fr;
  }
}

.c-hospital-desc {
  border: 1px solid $project-color-gray;
  padding: $project-gutter;
  margin-bottom: $project-gutter;
}

.c-rating-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  margin-bottom: $project-gutter;

  @supports (display: grid) {
    display: grid;
    grid-gap: $project-gutter;
    grid-template-columns: repeat(10, 1fr);
  }

  > li {
    border: 1px solid $project-color-gray;
    padding: $project-gutter;
    text-align: center;

    > strong {
      display: block;
      font-size: $project-font-size-lg;
    }
  }
}

.c-comment-list {
  border: 1px solid $project-color-gray;

  > li {
    padding: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid $project-color-gray;
    }

    &:hover {
      background-color: $project-color-white-dirty;
    }
  }
}

.c-image-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin-bottom: $project-gutter;

  @supports (display: grid) {
    display: grid;
    grid-gap: $project-gutter;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.c-image-item {
  height: 10rem;
  position: relative;
  border-radius: $project-radius-lg;
  border: 1px solid $project-color-gray;

  &:hover {
    .c-actions {
      display: block;
    }
  }

  .c-actions {
    position: absolute;
    top: $project-gutter-sm;
    right: $project-gutter-sm;
    z-index: 2;
    display: none;

    .c-btn {
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  > img {
    object-fit: cover;
    height: 10rem;
    border-radius: $project-radius-lg;
  }
}

.c-image-item--add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  background-color: $project-color-white;
  text-decoration: none;

  .c-form-control {
    filter: none;
    border: none;
  }
}

.c-search-field {
  .c-form-control {
    width: 10rem;
  }
}

.c-review-category-reorder {
  li {
    cursor: pointer;
  }
}

.c-categories-list--title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
  background-color: $project-color-gray-light;
  border-color: $project-color-gray;
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  padding: 0.5rem;
  margin: 0 !important;

  a {
    display: block;
    font-weight: 700;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: $project-color-brand-purple;
    }
  }
}

.c-cagegories-list {
  margin-top: 0;

  li {
    background-color: $project-color-white;
    border-color: $project-color-gray;
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    padding: 0.5rem;
    margin: 0;

    &:nth-child(even) {
      background-color: $project-color-white-shade;
    }

    &:last-child {
      border-width: 1px 1px 1px 1px;
    }

    a {
      font-size: 1rem;
      line-height: 2rem;
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        color: $project-color-brand-purple;
      }
    }
  }

  input {
    margin-right: 0.5rem;
  }
}

.required-field__wrap {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 7.7rem;
  }
}

.required-field__wrap-1 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 8.3rem;
  }
}

.required-field__wrap-2 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 9.6rem;
  }
}

.c-form-group--required {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 5.7rem;
  }
}

.c-form-group--required-1 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 5.3rem;
  }
}

.c-category-required__field-1 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 6.5rem;
  }
}

.c-category-required__field-2 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 3.3rem;
  }
}

.c-category-required__field-3 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 7.3rem;
  }
}

.c-category-required__field-4 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 7rem;
  }
}

.c-category-required__field-5 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 3.6rem;
  }
}

.c-category-required__field-6 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 1.6rem;
  }
}

.c-category-required__field-7 {
  position: relative;

  > span {
    position: absolute;
    color: $project-color-brand-red;
    left: 6.8rem;
  }
}

.c-boxed-list {
  margin-top: 1rem;

  li {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    margin: 0;
  }

  div {
    background-color: $project-color-white;
    border-color: $project-color-gray;
    border-style: solid;
    border-width: 1px 1px 0px 1px;

    &:nth-child(even) {
      background-color: $project-color-white-shade;
    }

    &:last-child {
      border-width: 1px 1px 1px 1px;
    }
  }
}

.c-review-detail {
  padding: 2rem;
  background-color: $project-color-gray-light;
  margin-bottom: 4rem;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
}

.c-review-comment-form {
  background-color: $project-color-white;
  border-color: $project-color-gray;
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  padding: 0.5rem;
  margin: 0;

  .c-control-label {
    font-weight: 700;
  }

  textarea {
    margin-bottom: $project-gutter;
  }
}
