/**
 * Base styles
 *
 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  outline: none;
}

html,
body {
  color: $project-color-text;
  font-family: $project-font-stack;
  font-size: $project-font-size-body;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
}

html,
body,
#app {
  display: flex;
  flex-direction: column;
}

body,
#app {
  flex: 1 1 auto;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $project-color-app-bg;
  min-height: 100%;
}

body {
  overflow-y: auto;
  line-height: 1.5;
  background-color: $project-color-white-dirty;
}

button {
  color: $project-color-text;
  cursor: pointer;
}

a {
  color: $project-color-text;
  cursor: pointer;
}

input {
  font-family: $project-font-stack;
}

svg {
  fill: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
table {
  &:not(:last-child) {
    margin-bottom: $project-gutter;
  }
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

small {
  font-size: var(--font-sm);
}

hr {
  margin: 1rem 0;
  border: none;
  border-bottom: 1px solid #cccccc;
}

section {
  + section {
    margin-top: 2rem;
  }
}
