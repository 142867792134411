/**
 * Overrides
 *
 */

[class^='makeStyles-root'] {
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  text-align: center;

  .error,
  .warning,
  .success,
  .info {
    width: 100%;
    padding: $project-gutter;
    color: $project-color-white;
    background-color: $project-color-gray-light;
  }

  .SnackbarItem-collapseWrapper-28 {
    margin: 0 !important;
  }

  .error {
    background-color: $project-color-brand-red;
  }

  .warning {
    background-color: $project-color-brand-purple;
  }

  .success {
    background-color: $project-color-brand-green;
  }

  .info {
    background-color: $project-color-brand-blue;
  }
}

.makeStyles-top-3 {
  top: 0 !important;
}

.makeStyles-right-9 {
  right: 0 !important;
}
